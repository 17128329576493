import React, { useEffect, useRef } from 'react'
import Matter from 'matter-js'
// require('../../polyfills/pathseg.js')
// window.decomp = require('poly-decomp');


class MatterScene extends React.Component {
  // textInput must be declared here so the ref can refer to it
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    // this.onCanvasLoaded = (c) => {
    //   if (c !== null) {
    //     this.setState({canvas: c});
    //   }
    // };
  }

  componentDidMount() {
    // console.log(this.canvas)
    // this.onCanvasLoaded = (c) => {
    //   if (c !== null) {
    //     this.setState({canvas: c});
    //   }
    // };

    // this.svgPaths = this.refs.svgDOM
    // const vertices = Matter.Vertices.scale(Matter.Svg.pathToVertices(this.refs.letterO, 10), 1,1)
    // const bodies = Matter.Bodies.fromVertices(400, 400, vertices, {
    // }, true);
    // console.log(bodies)r

    this.height = window.innerHeight
    this.width = window.innerWidth

// module aliases
    const Engine = Matter.Engine,
      Render = Matter.Render,
      Runner = Matter.Runner,
      Body = Matter.Body,
      Composites = Matter.Composites,
      Common = Matter.Common,
      Constraint = Matter.Constraint,
      MouseConstraint = Matter.MouseConstraint,
      Mouse = Matter.Mouse,
      World = Matter.World,
      Bodies = Matter.Bodies;
    // create an engine
    const engine = Engine.create();
    const world = engine.world;


    // console.log(this.canvas, 'mounted canvas')
// create a renderer
    const render = Render.create({
      element: this.canvas,
      engine: engine
    });

    render.options.wireframeBackground = 'transparent';
    render.options.background = 'transparent';


    // run the engine
    Engine.run(engine);

    // run the renderer
    Render.run(render);

    render.canvas.height = this.height
    render.canvas.width = this.width

    // create runner
    const runner = Runner.create();
    Runner.run(runner, engine);

    var bridge = Composites.stack(160, 290, 15, 1, 0, 0, function(x, y) {
      return Bodies.rectangle(x - 20, y, 53, 20, {
        collisionFilter: { group: group },
        chamfer: 5,
        density: 0.005,
        frictionAir: 0.05,
        render: {
          fillStyle: '#575375'
        }
      });
    });

    Composites.chain(bridge, 0.3, 0, -0.3, 0, {
      stiffness: 1,
      length: 0,
      render: {
        visible: false
      }
    });

    var stack = Composites.stack(250, 50, 5, 15, 0, 0, function(x, y) {
      return Bodies.rectangle(x, y, 50, 50, Common.random(20, 40));
    });

    var weight = Bodies.rectangle(400, 0, 200, 100)

    // add bodies
    var group = Body.nextGroup(true);


    // add all of the bodies to the world
    World.add(world, [
      bridge,
      weight,
      Constraint.create({
        pointA: { x: 140, y: 300 },
        bodyB: bridge.bodies[0],
        pointB: { x: -25, y: 0 },
        length: 2,
        stiffness: 0.9
      }),
      Constraint.create({
        pointA: { x: 660, y: 300 },
        bodyB: bridge.bodies[bridge.bodies.length - 1],
        pointB: { x: 25, y: 0 },
        length: 2,
        stiffness: 0.9
      })
    ]);

    // add mouse control
    const mouse = Mouse.create(render.canvas)
    mouse.element.removeEventListener("mousewheel", mouse.mousewheel);
    mouse.element.removeEventListener("DOMMouseScroll", mouse.mousewheel);
    const mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.1,
          render: {
            visible: false
          }
        }
      });

    World.add(world, mouseConstraint);

  }

render() {
  return <div id="MatterCanvas" ref={(mount) => {
    if (typeof this.canvas === 'undefined') {
      this.canvas = mount
    }
  }}>
      <svg data-name="Ebene 1" ref="svgDOM" width="34.89mm" height="3.09mm" viewBox="0 0 98.9 8.76">
        <path ref="letterZ" d="M-1070.35,444.29h58.66l-3.36,34.19h-106.12V448.22l54.55-68.94h-53.24V345.09H-1016v30.45Z"/>
        <path ref="letterW" d="M12.18 5.32l-.78 3.25H9.01L7.27 2.23h2.5l.67 3.36.79-3.3h2l.81 3.19.65-3.25h2.41l-1.74 6.34h-2.3z" fill="#222221"/>
        <path ref="letterE" d="M23.68 6.48a2.57 2.57 0 01-1 1.72 3.57 3.57 0 01-2.09.56 3.28 3.28 0 01-2.49-.87 3.4 3.4 0 01-.82-2.41 4.58 4.58 0 01.22-1.44 3 3 0 01.65-1.09 2.89 2.89 0 011-.68 3.77 3.77 0 011.38-.23 3.52 3.52 0 011.34.23 2.71 2.71 0 011 .65 2.79 2.79 0 01.61 1 4.45 4.45 0 01.2 1.35v.65h-4a1.19 1.19 0 00.26.86.82.82 0 00.61.22.68.68 0 00.75-.51zm-3.08-2.7a.91.91 0 00-.62.21.93.93 0 00-.26.69h1.66a1 1 0 00-.22-.7.73.73 0 00-.56-.2z" fill="#222221"/>
        <path ref="letterI" d="M24.67 0h2.57v1.62h-2.57zm0 2.23h2.57v6.34h-2.57z" fill="#222221"/>
        <path ref="letterH"d="M28.44 0h2.57v2.78a2.42 2.42 0 01.72-.56 2.13 2.13 0 011-.21 2 2 0 011.53.58 2.3 2.3 0 01.56 1.67v4.28h-2.58V4.78a.69.69 0 00-.13-.47.47.47 0 00-.37-.15.87.87 0 00-.37.07.59.59 0 00-.25.22.38.38 0 00-.08.17 1 1 0 000 .28v3.69h-2.6z" fill="#222221"/>
        <path ref="letterU" d="M42.2 8.57h-2.49V7.9a2.43 2.43 0 01-.74.62 2.08 2.08 0 01-1.03.26 1.94 1.94 0 01-1.49-.59 2.34 2.34 0 01-.51-1.69V2.23h2.57v3.84a.62.62 0 00.13.45.45.45 0 00.35.14.64.64 0 00.59-.28.48.48 0 00.08-.16 1 1 0 000-.27V2.23h2.56z" fill="#222221"/>
        <path ref="letterN" d="M43.4 2.23h2.54v.66a2.5 2.5 0 01.76-.62 2.13 2.13 0 011-.23 1.94 1.94 0 011.52.59 2.31 2.31 0 01.56 1.66v4.28h-2.57V4.78a.76.76 0 00-.13-.48.52.52 0 00-.38-.14.86.86 0 00-.36.07.56.56 0 00-.26.22.54.54 0 00-.08.17 1 1 0 000 .28v3.69h-2.6z" fill="#222221"/>
        <path ref="letterD" d="M54.94 8.57v-.63a2 2 0 01-1.73.82 2.19 2.19 0 01-1.82-.84 3.82 3.82 0 01-.67-2.43 5.19 5.19 0 01.2-1.48 3.08 3.08 0 01.53-1.09 2.26 2.26 0 01.82-.66 2.38 2.38 0 011-.22 2.07 2.07 0 01.92.19 1.64 1.64 0 01.6.49V0h2.57v8.57zm-.11-3.27a1.92 1.92 0 00-.18-1 .62.62 0 00-.57-.28.65.65 0 00-.58.3 1.82 1.82 0 00-.21 1 1.85 1.85 0 00.21 1 .64.64 0 00.58.31.61.61 0 00.56-.29 1.13 1.13 0 00.15-.39 4 4 0 000-.54z" fill="#222221"/>
        <path ref="letterE2" d="M64.76 6.48a2.5 2.5 0 01-1 1.72 3.51 3.51 0 01-2.08.56 3.28 3.28 0 01-2.49-.87 3.35 3.35 0 01-.82-2.41 4.31 4.31 0 01.22-1.44 2.91 2.91 0 01.65-1.09 2.81 2.81 0 011-.68 3.72 3.72 0 011.37-.23 3.54 3.54 0 011.35.23 2.71 2.71 0 011 .65 2.79 2.79 0 01.61 1 4.45 4.45 0 01.2 1.35v.65h-4a1.24 1.24 0 00.25.82.83.83 0 00.61.22.69.69 0 00.76-.51zm-3.07-2.7a.91.91 0 00-.62.21 1 1 0 00-.27.69h1.67a1 1 0 00-.22-.7.73.73 0 00-.56-.2z" fill="#222221"/>
        <path ref="letterR" d="M65.76 2.23h2.5v.7A2.06 2.06 0 0170 2.12a2 2 0 01.36 0h.28l-.27 2.13h-.31a2.49 2.49 0 00-.41 0h-.44a1.18 1.18 0 00-.47.18 1 1 0 00-.41.91v3.2h-2.57z" fill="#222221"/>
        <path ref="letterT" d="M72.27 4.23h-1v-2h1V.59h2.57v1.64h1.31v2h-1.31v1.72a.64.64 0 00.18.53.78.78 0 00.48.13 2.47 2.47 0 00.58-.07l.31 1.86a6 6 0 01-.78.2 4.91 4.91 0 01-.9.07 3.75 3.75 0 01-1.14-.15 1.68 1.68 0 01-.75-.46 1.85 1.85 0 01-.42-.81 4.82 4.82 0 01-.13-1.16z" fill="#222221"/>
        <path  ref="letterK" d="M80.19 5.91l-.57.57v2.09h-2.45V0h2.45v3.93l1.62-1.7h2.84l-2.14 2.11 2.35 4.23H81.6z" fill="#222221"/>
        <path ref="letterI2"ref="letterL" d="M84.94 0h2.57v1.62h-2.57zm0 2.23h2.57v6.34h-2.57z" fill="#222221"/>
        <path ref="letterL" d="M88.69 0h2.57v8.57h-2.57z" fill="#222221"/>
        <path ref="letterO" d="M366.09,481.47q-25.6,0-38.59-13.73t-13-38.21q0-24.84,13.64-38.76T367,376.85q25.39,0,38.48,13.73t13.07,38.21q0,24.84-13.53,38.76T366.09,481.47ZM354.5,429.16a48.55,48.55,0,0,0,.84,9.9,16.63,16.63,0,0,0,2.52,6.26,9.3,9.3,0,0,0,3.93,3.27,12,12,0,0,0,4.66.93,12.65,12.65,0,0,0,4.87-.93,9.22,9.22,0,0,0,3.92-3.27,16.8,16.8,0,0,0,2.52-6.26,58.82,58.82,0,0,0,0-19.81,16.82,16.82,0,0,0-2.52-6.25,9.22,9.22,0,0,0-3.92-3.27,12.65,12.65,0,0,0-4.87-.94,12,12,0,0,0-4.66.94,9.3,9.3,0,0,0-3.93,3.27,16.65,16.65,0,0,0-2.52,6.25A48.65,48.65,0,0,0,354.5,429.16Z" fill="#222221"/>
      </svg>
    </div>
}
}

export default MatterScene







