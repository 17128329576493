import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import MatterScene from '../components/MatterScene'

import Navbar from '../components/Navbar'
import BlogRoll from '../components/BlogRoll'
import ThreeDimensionalScene from '../components/ThreeDimensionalScene'

import "./styles.sass"

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    {/*<ThreeDimensionalScene/>*/}
    {/*<Navbar/>*/}
    {/*<MatterScene/>*/}

    <div className="section logo-section align-center justify-center">
          <div className="justify-start align-start">
            <div className="up-side-down">
              {/*<div className="vertical">*/}
                {/*<div><h2>Graphics</h2></div>*/}
                {/*<div><h2>Digitals</h2></div>*/}
                {/*<div><h2>Concepts</h2></div>*/}
              {/*</div>*/}
              <h1>We tend <br />  to  surprise.</h1>
              <a href="mailto:info@200kilo.com">
                <div className="circled">
                  <h1>Try us</h1>
                </div>
              </a>
            </div>
          </div>
    </div>
    {/*<div className="section about-section align-start justify-center v-50">*/}
      {/*<div className="justify-center ">*/}
        {/*<article className="about-text">*/}
          {/*<label className="article-heading">{mainpitch.title}</label>*/}
          {/*<p>*/}
            {/*{mainpitch.description}*/}
          {/*</p>*/}
        {/*</article>*/}
      {/*</div>*/}
    {/*</div>*/}
    {/*<section className="section section--gradient">*/}
      {/*<div className="container">*/}
        {/*<div className="section">*/}
          {/*<div className="columns">*/}
            {/*<div className="column is-10 is-offset-1">*/}
              {/*<div className="content">*/}
                {/*<div className="column is-12">*/}
                  {/*<BlogRoll />*/}
                  {/*<div className="column is-12 has-text-centered">*/}
                    {/*<Link className="btn" to="/blog">*/}
                      {/*More*/}
                    {/*</Link>*/}
                  {/*</div>*/}
                {/*</div>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*</div>*/}
      {/*</div>*/}
    {/*</section>*/}
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  const [scrollHeight, setScrollHeight] = useState(0);
  const handleScroll = (e) => {
    console.log('scrolling', e)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
