import React from 'react'
import { Helmet } from 'react-helmet'
import Index from './Footer'
import Navbar from './Navbar'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from "gatsby"

import './foundation.sass'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div className="white-background">
      <Helmet>
        <html className="white-background" lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        {/*<script src="https://cdn.jsdelivr.net/npm/pathseg@1.2.0/pathseg.min.js" type="text/javascript" />*/}
        {/*<script src="https://cdn.jsdelivr.net/npm/poly-decomp@0.3.0/build/decomp.min.js" type="text/javascript" />*/}
        {/*<script src="./src/polyfills/decomp.js" type="text/javascript" />*/}

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/pig_cursor.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/pig_cursor.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/pig_cursor.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/pig_cursor.png`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content="Zweihundertkilo" />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix("/")}img/pig_cursor.png`} />
      </Helmet>
      {/*<Navbar />*/}
      <div >{children}</div>
      {/*<Index />*/}
    </div>
  )
}

export default TemplateWrapper
